import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const GuaranteePackages = () => {
  const [openInfo, setOpenInfo] = useState(null);

  const packages = [
    {
      name: "Bronze Package",
      percentage: 10,
      coverage: 300,
      medalColor: "#CD7F32",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, selecting the Bronze
            package means 10% (€10) is deducted from your earnings to provide up
            to €300 in coverage. You'd receive €90, and if damage occurs to your
            item, you have up to €300 in coverage.
          </p>
        </>
      ),
    },
    {
      name: "Silver Package",
      percentage: 15,
      coverage: 600,
      medalColor: "#C0C0C0",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, selecting the Silver
            package means 15% (€15) is deducted, leaving you €85. In return, you
            have coverage up to €600 if something goes wrong with your item.
          </p>
        </>
      ),
    },
    {
      name: "Gold Package",
      percentage: 20,
      coverage: 1000,
      medalColor: "#FFD700",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, with the Gold package
            20% (€20) is deducted, leaving you €80. In exchange, you receive up
            to €1,000 in coverage to protect your item.
          </p>
        </>
      ),
    },
  ];

  const toggleInfo = (index) => {
    setOpenInfo(openInfo === index ? null : index);
  };

  return (
    <div className="bg-gray-100 py-10 px-5">
      <div className="max-w-6xl mx-auto">
        {/* Guarantee Packages Header */}
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-3xl font-bold mb-4 text-teal-700">
            Guarantee Package Options
          </h2>
          <p className="text-gray-700 md:text-lg">
            Lenders can choose a guarantee package to help protect your item. A
            small percentage of your rental earnings is deducted in exchange for
            coverage. For full details on what our guarantee includes, please
            visit our{" "}
            <Link
              to="/guarantee"
              className="text-blue-500 underline hover:font-semibold"
            >
              guarantee information page
            </Link>
            .
          </p>
        </div>

        {/* Guarantee Packages Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {packages.map((pkg, i) => (
            <div
              key={i}
              className="bg-white rounded-lg shadow-lg p-4 md:p-6 flex flex-col items-center hover:shadow-xl transition duration-300 relative"
            >
              <FontAwesomeIcon
                icon={faMedal}
                size="3x"
                style={{ color: pkg.medalColor }}
                className="mb-4"
              />
              <h3 className="text-lg md:text-xl font-bold text-gray-700 mb-2">
                {pkg.name}
              </h3>
              <p className="text-gray-700 text-sm md:text-base text-center mb-4">
                <span className="font-bold">{pkg.percentage}%</span> of earnings
                for up to <span className="font-bold">€{pkg.coverage}</span>{" "}
                cover.
              </p>

              {/* Info Icon to toggle scenario */}
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                onClick={() => toggleInfo(i)}
                aria-label="Show more info"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </button>

              {/* Scenario / Additional Info */}
              {openInfo === i && (
                <div className="mt-2 w-full border-t border-gray-200 pt-2">
                  {pkg.scenario}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuaranteePackages;
