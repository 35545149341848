import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { BeatLoader } from "react-spinners";
import formatCurrency from "../utils/formatCurrency";

// Custom Option component for React Select
const Option = (props) => {
  const { data, isDisabled } = props;
  const booking = data.booking;

  return (
    <components.Option {...props} isDisabled={isDisabled}>
      <div className={`flex items-center ${isDisabled ? "opacity-50" : ""}`}>
        {booking.listingImage ? (
          <img
            src={booking.listingImage}
            alt={booking.listingTitle}
            className="w-12 h-12 object-cover rounded-md mr-3"
          />
        ) : (
          <div className="w-12 h-12 bg-gray-200 rounded-md mr-3 flex items-center justify-center">
            <span className="text-gray-500">No Image</span>
          </div>
        )}
        <div>
          <p className="text-sm font-medium">{booking.listingTitle}</p>
          <p className="text-xs text-gray-500">
            Rented by {booking.renterUsername} (
            {firestoreTimestampToDate(booking.startDate)?.toLocaleDateString()}{" "}
            - {firestoreTimestampToDate(booking.endDate)?.toLocaleDateString()})
          </p>
          <p className="text-xs">
            Guarantee:{" "}
            {booking.guaranteePackage.name
              ? booking.guaranteePackage.name
              : "None"}
          </p>
        </div>
      </div>
    </components.Option>
  );
};
// Custom SingleValue component for React Select
const SingleValue = (props) => {
  const { data } = props;
  const booking = data.booking;

  return (
    <components.SingleValue {...props}>
      <div className="flex items-center">
        {booking.listingImage ? (
          <img
            src={booking.listingImage}
            alt={booking.listingTitle}
            className="w-8 h-8 object-cover rounded-md mr-2"
          />
        ) : (
          <div className="w-8 h-8 bg-gray-200 rounded-md mr-2 flex items-center justify-center">
            <span className="text-gray-500 text-xs">No Image</span>
          </div>
        )}
        <div>
          <p className="text-sm font-semibold">
            {booking.listingTitle}
            {booking.isExpired && (
              <span className="text-xs text-red-500 ml-2">(Expired)</span>
            )}
          </p>
          <div className="flex flex-col gap-1">
            <p className="text-xs text-gray-500 mt-1">
              {firestoreTimestampToDate(booking.startDate).toLocaleDateString()}{" "}
              - {firestoreTimestampToDate(booking.endDate).toLocaleDateString()}
            </p>
            <p className="text-xs text-gray-500">
              <span className="font-semibold">Rented by:</span>{" "}
              {booking.renterUsername}
            </p>
            <p className="text-xs text-gray-500">
              <span className="font-semibold">Guarantee Package:</span>{" "}
              {booking.guaranteePackage.name}
            </p>
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
};

const Claim = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [issueDescription, setIssueDescription] = useState("");
  const [requestedAmountCents, setRequestedAmountCents] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingClaim, setSubmittingClaim] = useState(false);
  const [maxClaimCents, setMaxClaimCents] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  // Cloudinary upload states
  const [imageFiles, setImageFiles] = useState([]); // Stores the file info
  const [imageURLs, setImageURLs] = useState([]); // Stores the secure URLs

  const navigate = useNavigate();

  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      toast.error("You need to be logged in to submit a claim.", {
        autoClose: 4000,
      });
      navigate("/");
      return;
    }

    const fetchBookings = async () => {
      try {
        const userUid = currentUser.uid;
        const bookingsRef = collection(db, "users", userUid, "bookings");
        const bookingsSnapshot = await getDocs(bookingsRef);

        const bookingsData = [];

        for (const bookingDoc of bookingsSnapshot.docs) {
          const bookingData = bookingDoc.data();
          const listingId = bookingData.listingId;

          // Fetch listing details
          const listingDocRef = doc(db, "listings", listingId);
          const listingDocSnap = await getDoc(listingDocRef);
          const listingData = listingDocSnap.exists()
            ? listingDocSnap.data()
            : {};

          const listingTitle = listingData.title || "Unknown Listing";
          const listingImage = listingData.imageURLs
            ? listingData.imageURLs[0]
            : null;

          // Fetch renter username
          const renterUid = bookingData.renterUid;
          const renterDocRef = doc(db, "users", renterUid);
          const renterDocSnap = await getDoc(renterDocRef);
          const renterUsername = renterDocSnap.exists()
            ? renterDocSnap.data().username
            : "Unknown User";

          // Format start and end dates
          const startDate = bookingData.startDate
            ? firestoreTimestampToDate(
                bookingData.startDate
              ).toLocaleDateString()
            : "Unknown Date";
          const endDate = bookingData.endDate
            ? firestoreTimestampToDate(bookingData.endDate).toLocaleDateString()
            : "Unknown Date";

          // Check if booking is expired (endDate > 7 days ago)
          let isExpired = false;
          if (endDate) {
            const currentDate = new Date();
            const differenceInTime = currentDate - endDate;
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            if (differenceInDays > 7) {
              isExpired = true;
            }
          }

          // Get guarantee package
          const guaranteePackage = bookingData.guaranteePackage || {
            name: "None",
          };

          bookingsData.push({
            id: bookingDoc.id,
            listingTitle,
            listingImage,
            renterUsername,
            startDate,
            isExpired,
            endDate,
            guaranteePackage,
            ...bookingData,
          });
        }

        console.log(bookingsData);

        setBookings(bookingsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        toast.error("Failed to load bookings.");
        setLoading(false);
      }
    };

    fetchBookings();
  }, [currentUser]);

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  // Cloudinary upload widget code
  const cloudName = "prodcloudinary";
  const uploadPreset = "kitShareUploadPreset";

  // Open Cloudinary Upload Widget
  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: true,
          maxFiles: 5 - imageFiles.length,
          sources: ["local", "camera"],
          folder: "guaranteeClaims",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "auto",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setImageFiles((prevFiles) => [...prevFiles, result.info]); // Save the file info for later upload
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]); // Display the uploaded image as thumbnail
            console.log("File uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload file. Please try again.");
          }
        }
      )
      .open();
  };

  // Function to remove an uploaded image
  const removeImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageURLs((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleBookingChange = (selectedOption) => {
    setSelectedOption(selectedOption); // New state to hold the selected option
    setSelectedBooking(selectedOption.booking); // Set to the full booking object
    setMaxClaimCents(selectedOption.booking.guaranteePackage?.maxCoverageCents);
  };

  // Increment claim count
  const incrementClaimCount = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      claimCount: increment(1),
    });
  };

  // Send guarantee email to support@kitshare.ie and inform the guarantee-claims slack channel
  const sendGuaranteeEmailAndSlackMessage = async (booking) => {
    const {
      id,
      listingId,
      listingTitle,
      startDate,
      endDate,
      guaranteePackage,
      renterUsername,
      renterUid,
    } = booking;

    console.log(booking);

    const body = JSON.stringify({
      username: currentUser.username,
      uid: currentUser.uid,
      bookingId: id,
      listingId,
      listingTitle: listingTitle,
      startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
      endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
      guaranteePackage,
      renterUsername,
      renterUid,
      issueDescription,
      requestedAmountCents,
      imageURLs,
    });

    try {
      const response = await fetch(
        `https://claimemail-claimemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
    } catch (error) {
      toast.error(
        "Failed to send email to KitShare, please contact us using other methods.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmittingClaim(true);

    // Perform validation
    if (!selectedBooking) {
      toast.error("Please select a booking.");
      return;
    }
    if (!issueDescription.trim()) {
      toast.error("Please describe the issue.");
      return;
    }
    if (
      !requestedAmountCents ||
      isNaN(requestedAmountCents) ||
      requestedAmountCents <= 0
    ) {
      toast.error("Please enter a valid requested amount.");
      return;
    }
    if (imageFiles.length === 0) {
      toast.error("Please upload the required documents.");
      return;
    }

    try {
      // Prepare claim data
      const claimData = {
        userUid: currentUser.uid,
        bookingId: selectedBooking.id,
        issueDescription,
        requestedAmountCents: parseFloat(requestedAmountCents),
        uploadedFiles: imageURLs, // Store the URLs of the uploaded files
        createdAt: new Date(),
        status: "Pending",
      };

      console.log(claimData);

      // Save the claim to Firestore (you may need to set up security rules)
      await addDoc(collection(db, "claims"), claimData);

      // Send automated email to KitShare support and Slack channel
      await sendGuaranteeEmailAndSlackMessage(selectedBooking);

      // Increment this users claim count
      await incrementClaimCount();

      toast.success(
        "Your claim has been submitted. KitShare aims to respond to all claims within 7 days."
      );
      setSubmittingClaim(false);
      navigate("/");
    } catch (error) {
      console.error("Error submitting claim:", error);
      toast.error("Failed to submit your claim. Please try again.");
      setSubmittingClaim(false);
    }
  };

  // Prepare options for React Select
  const bookingOptions = bookings.map((booking) => {
    const guaranteePackageName = booking.guaranteePackage?.name || "None";
    const isDisabledGuarantee = !["Bronze", "Silver", "Gold"].includes(
      guaranteePackageName
    );
    const isExpired = booking.isExpired;

    // Append "(Expired)" to the title if the booking is expired
    const titleWithExpiry = isExpired
      ? `${booking.listingTitle} (Expired)`
      : booking.listingTitle;

    const isDisabled = isDisabledGuarantee || isExpired;

    return {
      value: booking.id,
      label: titleWithExpiry,
      booking: booking,
      isDisabled: isDisabled,
    };
  });

  return (
    <div className="max-w-3xl mx-auto px-2 md:px-6 md:py-2 mt-5 bg-white shadow-md rounded-lg">
      {/* Header */}
      <h1 className="text-xl mb-3 header__center font-semibold">
        Submit a Claim
      </h1>
      <p className="text-sm md:text-base text-gray-700 mb-3 md:mb-8 text-center">
        We understand that unfortunate situations can occur. At KitShare, we're
        committed to supporting our community. If you've purchased a guarantee
        package and need to make a claim, please provide the details below, and
        we'll do our best to assist you.
      </p>

      {/* Claim Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Booking Selector */}
        <div>
          <label htmlFor="booking" className="text-sm font-semibold mb-2">
            Select Booking
          </label>
          {loading ? (
            <p>Loading bookings...</p>
          ) : bookings.length > 0 ? (
            <Select
              options={bookingOptions}
              components={{ Option, SingleValue }}
              value={selectedOption}
              onChange={handleBookingChange}
              isOptionDisabled={(option) => option.isDisabled}
              placeholder="Choose a booking"
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "4px",
                  borderColor: "#cbd5e0",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#a0aec0",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#e6fffa"
                    : state.isFocused
                    ? "#f0fff4"
                    : null,
                  color: state.isDisabled ? "#a0aec0" : "#2d3748",
                  cursor: state.isDisabled ? "not-allowed" : "default",
                }),
              }}
            />
          ) : (
            <p>No bookings found.</p>
          )}
        </div>

        {/* Issue Description */}
        <div>
          <p className="text-sm font-semibold mb-1">Describe the Issue</p>
          <textarea
            id="issueDescription"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            rows="4"
            className="text-sm md:text-base block w-full p-2 border border-gray-300 rounded-md"
            placeholder="Please provide details about the issue that occurred."
          />
        </div>

        {/* Requested Amount */}
        <div>
          <div className="flex align-center items-center gap-2">
            <p className="text-sm font-semibold">Requested Amount (€)</p>
            {maxClaimCents ? (
              <p className="text-sm font-semibold">
                (Max claim: €{formatCurrency(maxClaimCents)}){/*  */}
              </p>
            ) : null}
          </div>
          <input
            type="number"
            id="requestedAmountCents"
            value={requestedAmountCents}
            onChange={(e) => setRequestedAmountCents(e.target.value)}
            className="text-sm md:text-base mt-1 block w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter the amount you're requesting"
            min="0"
            max={maxClaimCents}
            step="0.01"
          />
        </div>

        {/* File Uploads */}
        <div>
          <label className="block text-sm font-semibold mb-2">
            Upload Required Documents
          </label>
          {/* Additional Information */}
          <ul className="list-disc list-inside text-sm text-gray-700 mb-4">
            <li>
              <strong>Photos/Videos:</strong> Time-stamped photos or videos of
              the item before and after the rental period, showing the condition
              of the item.
            </li>
            <li>
              <strong>Police Report:</strong> In cases of theft, a police report
              must be filed and submitted.
            </li>
            <li>
              <strong>Repair Estimate:</strong> For damages, an estimate from a
              professional repair service.
            </li>
          </ul>

          <div className="flex justify-center md:justify-start">
            <button
              type="button"
              onClick={openUploadWidget}
              className={`btn-continue px-5 ${
                !currentUser || !selectedBooking
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!currentUser || !selectedBooking}
            >
              Upload Documents
            </button>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            You can upload up to 5 files. Accepted formats: images, videos,
            PDFs.
          </p>
          {imageURLs.length > 0 && (
            <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
              {imageURLs.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Uploaded file ${index + 1}`}
                    className="w-full h-32 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full px-2 py-1 hover:font-bold"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="w-full flex justify-center">
          <button
            type="submit"
            className={
              parseInt(requestedAmountCents) > maxClaimCents
                ? "btn-secondary px-8 cursor-not-allowed"
                : "btn-continue px-8 cursor-pointer"
            }
            disabled={
              parseInt(requestedAmountCents) > maxClaimCents ||
              submittingClaim === true
            }
          >
            {submittingClaim ? <BeatLoader color="white" /> : "Submit Claim"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Claim;
