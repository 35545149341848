import React, { useEffect, useState, useContext } from "react";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase.config";
import ListingCard from "../components/ListingCard";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../hooks/useAuth";

const MyListings = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const fetchUserListings = async () => {
        const userListings = [];
        console.log(currentUser);
        const q = query(
          collection(db, "listings"),
          where("lenderUid", "==", currentUser.uid),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          userListings.push({ id: doc.id, ...doc.data() });
        });

        setListings(userListings);
        setIsLoading(false);
      };

      fetchUserListings();
    }
  }, [currentUser]);

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 text-center my-5 text-xl font-bold header__center">
          My Listings
        </h1>
      </div>
      <div className="flex justify-center w-full mt-5 px-2">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
          {isLoading ? (
            <div className="flex justify-center align-center items-center">
              <BeatLoader color="#14b8a6" />
            </div>
          ) : (
            listings.map((listing) => (
              <ListingCard key={listing.id} listingDetails={listing} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MyListings;
