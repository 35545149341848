import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch";
import ListingCard from "../components/ListingCard";
import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import AlgoliaSearch from "../components/AlgoliaSearch";
import LocationSearch from "../components/LocationSearch";
import notFound from "../assets/notFound.png";
import { useAuth } from "../hooks/useAuth";
import Modal from "react-modal";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { FaLocationDot, FaX } from "react-icons/fa6";
import GoogleLocationSearch from "../components/GoogleLocationSearch";

// Initialize Algolia search client
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "90%",
    maxWidth: "600px",
    maxHeight: "90vh",
    overflow: "auto",
    borderRadius: "8px",
    padding: "0",
  },
};

const defaultMapCenter = {
  lat: 53.349805,
  lng: -6.26031, // Default center (Dublin, Ireland)
};

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedGeolocation, setSelectedGeolocation] =
    useState(defaultMapCenter);
  const [selectedLocation, setSelectedLocation] = useState({
    formattedAddress: "Dublin",
    addressComponents: {},
  });
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [locationInputValue, setLocationInputValue] = useState("");

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");
  const { currentUser } = useAuth();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  // Set initial geolocation based on user status
  useEffect(() => {
    if (currentUser && currentUser._geoloc) {
      setSelectedGeolocation({
        lat: currentUser._geoloc.lat,
        lng: currentUser._geoloc.lng,
      });
      setSelectedLocation({
        formattedAddress: currentUser.formattedAddress,
        addressComponents: currentUser.addressComponents,
      });
      setLocationInputValue(currentUser.formattedAddress);
    } else {
      setSelectedGeolocation(defaultMapCenter);
      setSelectedLocation({
        formattedAddress: "Dublin",
        addressComponents: {},
      });
      setLocationInputValue("Dublin");
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);

      let indexName = "kitshare_listings";
      if (!query) {
        indexName = "kitshare_listings_createdAt_desc";
      }
      const index = searchClient.initIndex(indexName);

      const searchParams = {
        getRankingInfo: true,
        page,
        hitsPerPage: 16,
        aroundLatLng: `${selectedGeolocation.lat},${selectedGeolocation.lng}`,
        aroundRadius: 80000,
      };

      const { hits, nbPages } = await index.search(query || "", searchParams);

      const hitsWithAddedId = hits.map((hit) => ({
        ...hit,
        id: hit.objectID,
      }));

      setSearchResults((prevResults) =>
        page === 0 ? hitsWithAddedId : [...prevResults, ...hitsWithAddedId]
      );

      setTotalPages(nbPages);
      setIsLoading(false);
    };

    fetchSearchResults();
  }, [query, page, selectedGeolocation]);

  const handleShowMore = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleLocationChange = (location) => {
    if (location) {
      setSelectedGeolocation({
        lat: location.geolocation.lat,
        lng: location.geolocation.lng,
      });
      setSelectedLocation({
        formattedAddress: location.formattedAddress,
        addressComponents: location.addressComponents,
      });
      setLocationInputValue(location.formattedAddress);
      setPage(0);
    } else {
      setSelectedGeolocation(defaultMapCenter);
      setSelectedLocation({
        formattedAddress: "Dublin",
        addressComponents: {},
      });
      setLocationInputValue("Dublin");
    }
  };

  const handleMapClick = (event) => {
    setSelectedPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleConfirmLocation = () => {
    if (selectedPosition) {
      setSelectedGeolocation(selectedPosition);
      setSelectedLocation({
        formattedAddress: "Custom Location",
        addressComponents: {},
      });
      setLocationInputValue(""); // Reset input value here
      setPage(0);
      setIsMapModalOpen(false);
    }
  };

  return (
    <div className="mx-auto">
      <div className="bg-gray-100 pt-4 pb-2 mb-5">
        <div className="flex justify-center">
          <div className="w-full md:w-5/6 xl:w-1/2">
            <AlgoliaSearch />
          </div>
        </div>

        <div className="flex justify-center mb-4 px-4">
          <div className="w-full md:w-5/6 xl:w-1/2 bg-white p-4 rounded-md shadow-sm">
            <div className="flex items-center mb-3">
              <FaLocationDot className="w-5 h-5 mr-2 text-accent" />
              <h2 className="text-base md:text-lg font-semibold text-gray-700">
                Change Location
              </h2>
            </div>
            <div className="flex flex-col sm:flex-row items-center mb-3 gap-4 md:gap-0">
              <div className="w-full sm:w-2/3">
                {isLoaded ? (
                  <GoogleLocationSearch
                    onLocationSelect={handleLocationChange}
                    isLoaded={isLoaded}
                    inputValue={locationInputValue}
                    setInputValue={setLocationInputValue}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              <button
                onClick={() => setIsMapModalOpen(true)}
                className="px-4 btn-primary ml-5 text-sm md:text-base font-semibold"
              >
                <div className="flex justify-center items-center gap-1 px-5 md:px-2">
                  <FaLocationDot />
                  <p>Choose on Map</p>
                </div>
              </button>
            </div>
            {selectedLocation && (
              <div className="mt-3 bg-gray-50 p-3 rounded-md">
                <p className="text-gray-700 text-xs sm:text-sm">
                  <span className="font-medium">Search Focus Location:</span>{" "}
                  {selectedLocation.formattedAddress}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isMapModalOpen}
        onRequestClose={() => setIsMapModalOpen(false)}
        contentLabel="Select Location on Map"
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between gap-5 items-center p-4 border-b">
            <h2 className="text-lg font-semibold">Select Location on Map</h2>
            <button
              onClick={() => setIsMapModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaX />
            </button>
          </div>
          <div className="flex-1">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedGeolocation}
                zoom={8}
                onClick={handleMapClick}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
              >
                {selectedPosition && <Marker position={selectedPosition} />}
              </GoogleMap>
            ) : null}
          </div>
          <div className="flex justify-center p-4 border-t">
            <button
              onClick={handleConfirmLocation}
              className="px-4 btn-continue"
              disabled={!selectedPosition}
            >
              Confirm Location
            </button>
          </div>
        </div>
      </Modal>

      {/* Search Results */}
      <div className="px-2">
        <h2 className="text-lg md:text-xl font-semibold mb-4">
          Search Results for "{query}"
        </h2>
        {isLoading && page === 0 ? (
          <div className="flex justify-center items-center h-64">
            <SyncLoader size={15} color="#14b8a6" />
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
              {searchResults.length > 0 ? (
                searchResults.map((listingDetails, index) => (
                  <ListingCard listingDetails={listingDetails} key={index} />
                ))
              ) : (
                <div className="flex flex-col justify-center items-center h-64">
                  <img src={notFound} className="w-64 mb-4" alt="notFound" />
                  <p>No results found for "{query}".</p>
                </div>
              )}
            </div>
          </div>
        )}
        {page < totalPages - 1 && (
          <div className="flex justify-center mt-6">
            <button onClick={handleShowMore} className="btn-continue px-5 mb-5">
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
