import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMedal,
  faShieldAlt,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";

const Insurance = () => {
  return (
    <>
      <div className="bg-gray-50 pt-5 px-5">
        <div className="max-w-6xl mx-auto">
          {/* Friendly Welcome Message */}
          <div className="text-center mb-10">
            <h1 className="text-xl text-center md:text-2xl font-semibold mb-8 md:header__center">
              KitShare Guarantee
            </h1>
            <p className="text-sm md:text-base text-gray-700">
              We understand that accidents can happen. At KitShare, we're
              committed to supporting our community by offering comprehensive
              packages tailored to your needs. Rent with confidence knowing that
              we're here to help if something goes wrong.
            </p>
          </div>

          {/* Guarantee Packages */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Bronze Package */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col">
              <div className="flex flex-col items-center mb-4">
                <FontAwesomeIcon
                  icon={faMedal}
                  size="4x"
                  style={{ color: "#CD7F32" }}
                  className="mb-4"
                />
                <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
                  Bronze Package
                </h2>
                <p className="text-sm md:text-base text-gray-700 text-center mb-4">
                  <span className="font-bold">10%</span> of rental cost for up
                  to <span className="font-bold">€300</span> cover.
                </p>
              </div>
              <div className="flex-grow">
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Coverage Includes:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    Accidental Damage Protection
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    Theft Protection
                  </li>
                </ul>
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Exclusions:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Intentional Damage
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Wear and Tear
                  </li>
                </ul>
              </div>
              <p className="bg-gray-50 p-2 rounded-lg text-sm font-semibold text-center text-gray-700 mt-auto">
                Selected by item lenders for each rental
              </p>
            </div>

            {/* Silver Package */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col">
              <div className="flex flex-col items-center mb-4">
                <FontAwesomeIcon
                  icon={faMedal}
                  size="4x"
                  style={{ color: "#C0C0C0" }}
                  className="mb-4"
                />
                <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
                  Silver Package
                </h2>
                <p className="text-sm md:text-base text-gray-700 text-center mb-4">
                  <span className="font-bold">15%</span> of rental cost for up
                  to <span className="font-bold">€600</span> cover.
                </p>
              </div>
              <div className="flex-grow">
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Coverage Includes:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    All Bronze Package Benefits
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    Accidental Loss Protection
                  </li>
                </ul>
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Exclusions:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Intentional Damage
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Wear and Tear
                  </li>
                </ul>
              </div>
              <p className="bg-gray-50 p-2 rounded-lg text-sm font-semibold text-center text-gray-700 mt-auto">
                Selected by item lenders for each rental
              </p>
            </div>

            {/* Gold Package */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col">
              <div className="flex flex-col items-center mb-4">
                <FontAwesomeIcon
                  icon={faMedal}
                  size="4x"
                  style={{ color: "#FFD700" }}
                  className="mb-4"
                />
                <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
                  Gold Package
                </h2>
                <p className="text-sm md:text-base text-gray-700 text-center mb-4">
                  <span className="font-bold">20%</span> of rental cost for up
                  to <span className="font-bold">€1,000</span> cover.
                </p>
              </div>
              <div className="flex-grow">
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Coverage Includes:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    All Silver Package Benefits
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-green-500 mr-2 mt-1"
                    />
                    Full Coverage Protection
                  </li>
                </ul>
                <h3 className="text-base md:text-lg font-semibold mb-2">
                  Exclusions:
                </h3>
                <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Intentional Damage
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-red-500 mr-2 mt-1"
                    />
                    Wear and Tear
                  </li>
                </ul>
              </div>
              <p className="bg-gray-50 p-2 rounded-lg text-sm font-semibold text-center text-gray-700 mt-auto">
                Selected by item lenders for each rental
              </p>
            </div>
          </div>

          {/* Policy Overview */}
          <div className="mt-12">
            <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
              Policy Overview
            </h2>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              KitShare offers comprehensive packages to protect items rented
              through our platform. Our policies cover damages, theft, and
              accidental loss up to the maximum coverage amount specified in
              your chosen package.
            </p>

            <h3 className="text-base md:text-lg font-semibold mb-2">
              Coverage
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Theft Protection:</strong> Compensation if your rented
                item is stolen during the rental period.
              </li>
              <li>
                <strong>Damage Protection:</strong> Coverage for repair or
                replacement costs if your item is damaged during the rental.
              </li>
              <li>
                <strong>Accidental Loss:</strong> Protection against accidental
                loss of your item during the rental period.
              </li>
            </ul>

            <h3 className="text-base md:text-lg font-semibold mb-2">
              Exclusions
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Intentional Damage:</strong> Damage caused intentionally
                or through gross negligence.
              </li>
              <li>
                <strong>Wear and Tear:</strong> Normal wear and tear or gradual
                deterioration.
              </li>
              <li>
                <strong>Improper Use:</strong> Damage from misuse or using the
                item contrary to its intended purpose.
              </li>
              <li>
                <strong>Pre-existing Conditions:</strong> Issues that existed
                before the rental period.
              </li>
              <li>
                <strong>Lender Negligence:</strong> Items not properly
                maintained or inadequately described.
              </li>
              <li>
                <strong>Loss or Theft due to Renter's Negligence:</strong> Items
                lost or stolen as a result of the Renter’s failure to take
                reasonable precautions.
              </li>
              <li>
                <strong>Non-return of Items:</strong> Failing to return the item
                due to negligence (e.g., forgetfulness).
              </li>
            </ul>

            <h3 className="text-base md:text-lg font-semibold mb-2">
              Renter Responsibilities
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Care of Items:</strong> Renters must take reasonable
                care to prevent damage or loss.
              </li>
              <li>
                <strong>Proper Use:</strong> Items should be used as intended.
              </li>
              <li>
                <strong>Communication:</strong> Renters should inform the lender
                of issues during the rental.
              </li>
              <li>
                <strong>Documentation:</strong> Time-stamped photos or videos
                are encouraged for before/after condition checks.
              </li>
            </ul>

            <h3 className="text-base md:text-lg font-semibold mb-2">
              Claim Limits
            </h3>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              The maximum compensation under our guarantee packages is limited
              to the coverage specified by the selected package per rental
              transaction.
            </p>

            {/* Claim Process */}
            <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
              Claim Process
            </h2>
            <p className="text-sm md:text-base mb-4 text-gray-700">
              If you experience damage or loss, follow these steps to file a
              claim:
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Report the Incident:</strong> Lenders must report theft or
              damage within 7 days of the rental end date via the KitShare
              claims portal.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Submit Required Documentation:</strong>
            </p>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2 text-sm md:text-base text-gray-700">
              <li>
                <strong>Condition Evidence:</strong> Time-stamped before/after
                photos/videos.
              </li>
              <li>
                <strong>Police Report:</strong> For theft claims.
              </li>
              <li>
                <strong>Repair Estimate:</strong> For damage claims.
              </li>
            </ul>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Investigation:</strong> KitShare reviews all documentation
              and may interview both parties.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Decision:</strong> Expect a decision within 14 business
              days; both parties are notified by email.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2 mb-4">
              <strong>Payout:</strong> If approved, compensation is provided up
              to the coverage limit within 7 business days.
            </p>

            <div className="mt-6 text-center">
              <Link
                to="/claim"
                className="btn-primary px-5 text-sm md:text-base font-semibold"
              >
                Submit a Claim
              </Link>
            </div>
          </div>

          {/* Dispute Resolution */}
          <div className="mt-8 mb-8">
            <h3 className="text-base md:text-lg font-semibold mb-2">
              Dispute Resolution
            </h3>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              If you disagree with a claim decision, you can request a review by
              KitShare's claims review panel. The decision of the panel is
              final.
            </p>
            <p className="text-sm md:text-base mb-4">
              Email{" "}
              <a
                href="mailto:support@kitshare.ie"
                className="text-blue-500 underline"
              >
                support@kitshare.ie
              </a>{" "}
              for assistance with claims.
            </p>
            <p className="text-sm md:text-base text-gray-700">
              For full details, please refer to our{" "}
              <Link
                to="/terms-and-conditions"
                className="text-teal-600 font-semibold hover:underline hover:text-teal-800"
              >
                Terms &amp; Conditions
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Insurance;
