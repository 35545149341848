import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AlgoliaSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    navigate(`/search-results?q=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <div>
      <form
        onSubmit={handleSearch}
        //className="flex justify-center w-full md:w-2/3 lg:w-2/5 gap-2"
        className="flex justify-center w-full gap-2"
      >
        <input
          type="text"
          className="text-sm md:text-base border border-gray-300 p-2 rounded-md flex-grow focus:outline-none focus:border-teal-500 focus:border-2"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder="Search listings..."
        />
        <button
          className="bg-teal-600 text-white px-4 py-2 shadow-md rounded-md hover:bg-accentDarker"
          type="submit"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </div>
  );
};

export default AlgoliaSearch;
