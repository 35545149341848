import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faUpload,
  faSave,
  faCheckCircle,
  faXmarkCircle,
  faShieldAlt,
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase.config";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  deleteUser,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Modal from "react-modal";
import profilePicPlaceholder from "../assets/profilePicture.png";
import { FaX } from "react-icons/fa6";
import VeriffButton from "../components/VeriffButton";
import GoogleLocationSearch from "../components/GoogleLocationSearch";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    about: "",
    phoneNumber: "",
    emailVerified: false,
    profilePictureURL: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [usernameIsTaken, setUsernameIsTaken] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState(null);
  const [newLocation, setNewLocation] = useState({});
  const [newGeolocation, setNewGeolocation] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [addressComponents, setAddressComponents] = useState({});
  const [geolocation, setGeolocation] = useState({});
  const [futureBookings, setFutureBookings] = useState([]);
  const [originalAbout, setOriginalAbout] = useState("");
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState("");
  const [originalFormattedAddress, setOriginalFormattedAddress] = useState("");
  const [originalAddressComponents, setOriginalAddressComponents] = useState(
    {}
  );
  const [originalGeolocation, setOriginalGeolocation] = useState({});
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [isConfirmDeleteAccountModalOpen, setIsConfirmDeleteAccountModalOpen] =
    useState(false);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState("");
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const { username, email, phoneNumber, about } = formData;

  const auth = getAuth();

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isLocationModalOpen) {
      setNewLocation({});
      setNewGeolocation(null);
      setLocationInputValue("");
      setIsLocationChanged(false); // Reset the change flag
    }
  }, [isLocationModalOpen]);

  // Need to add this email verification check to ensure the users verified email is showcased immediately after they verify their email
  useEffect(() => {
    const checkEmailVerification = async () => {
      if (auth.currentUser) {
        try {
          await auth.currentUser.reload();
          const updatedEmailVerified = auth.currentUser.emailVerified;

          // Update local state
          setEmailVerified(updatedEmailVerified);

          // Optionally, update the context
          setCurrentUser((prevUser) => ({
            ...prevUser,
            emailVerified: updatedEmailVerified,
          }));
        } catch (error) {
          console.error("Error reloading user data:", error);
        }
      }
    };

    checkEmailVerification();
  }, [auth.currentUser, setCurrentUser]);

  useEffect(() => {
    loadCloudinaryScript(() => {
      console.log("cloudinary script loaded");
    });
  }, []);

  // Reset new location when modal closes without confirmation
  useEffect(() => {
    if (!isLocationModalOpen) {
      setNewLocation(null);
      setNewGeolocation(null);
      setLocationInputValue("");
    }
  }, [isLocationModalOpen]);

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const reauthenticateUser = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Since only email/password sign-in is allowed, no need to check providerId
      if (!deleteAccountPassword) {
        toast.error("Please enter your password to confirm account deletion.");
        return false;
      }

      const credential = EmailAuthProvider.credential(
        user.email,
        deleteAccountPassword
      );
      await reauthenticateWithCredential(user, credential);

      return true;
    } catch (error) {
      console.error("Error re-authenticating user:", error);
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect password. Please try again.");
      } else {
        toast.error("Failed to re-authenticate. Please try again.");
      }
      return false;
    }
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const fetchUserData = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setFormData(data);
      setCurrentUsername(data.username);

      setFormattedAddress(data.formattedAddress || "");
      setAddressComponents(data.addressComponents || {});
      setGeolocation(data._geoloc || {});

      // Set original values
      setOriginalAbout(data.about || "");
      setOriginalPhoneNumber(data.phoneNumber || "");
      setOriginalFormattedAddress(data.formattedAddress || "");
      setOriginalAddressComponents(data.addressComponents || {});
      setOriginalGeolocation(data._geoloc || {});

      if (data.profilePictureURL) {
        setProfilePictureURL(data.profilePictureURL);
      }
    }
  };

  const handleLocationSelected = (locationData) => {
    const selectedFormattedAddress = locationData.formattedAddress || "";
    const selectedGeolocation = locationData.geolocation || {};

    // Update newLocation and newGeolocation
    setNewLocation({
      formattedAddress: selectedFormattedAddress,
      addressComponents: locationData.addressComponents || {},
    });
    setNewGeolocation(selectedGeolocation);

    // Check if the selected location is different from the original
    const isDifferentLocation =
      selectedFormattedAddress !== originalFormattedAddress ||
      selectedGeolocation.lat !== originalGeolocation.lat ||
      selectedGeolocation.lng !== originalGeolocation.lng;

    setIsLocationChanged(isDifferentLocation);
    setLocationInputValue(selectedFormattedAddress);
  };

  // Debounce function
  function debounce(func, delay) {
    var inDebounce;

    return function () {
      var context = this;
      var args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(function () {
        func.apply(context, args);
      }, delay);
    };
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const checkUsernameAvailability = async (username) => {
    if (username.length >= 4) {
      console.log("running query");
      // Reference to the users collection
      const usersRef = collection(db, "users");

      // Create a query against the collection to check for the username
      const q = query(usersRef, where("username", "==", username));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If the querySnapshot is empty, then the username is available
      return querySnapshot.empty;
    }
  };

  const checkUsernameAvailabilityDebounce = useCallback(
    debounce((username) => {
      console.log("checking username availability");
      checkUsernameAvailability(username).then((isAvailable) => {
        if (isAvailable) {
          console.log(`${username} is available`);
          setUsernameIsAvailable(true);
          setUsernameIsTaken(false); // Ensure to reset this state
        } else {
          setUsernameIsAvailable(false);
          setUsernameIsTaken(true);
          console.log(`${username} is not available`);
        }
      });
    }, 2000),
    [] // Dependencies array
  );

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      username: username,
    }));
    setUsernameIsAvailable(false);
    setUsernameIsTaken(false);
    checkUsernameAvailabilityDebounce(username);
  };

  const cloudName = "prodCloudinary";
  const uploadPreset = "kitShareUploadPreset";

  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: false,
          maxFiles: 1,
          sources: ["local", "camera"],
          maxFileSize: 5500000,
          folder: "profilePictures",
          context: {
            alt: "profile_picture",
            caption: "profile picture",
          },
          resourceType: "image",
        },
        async (error, result) => {
          if (!error && result && result.event === "success") {
            setProfilePictureURL(result.info.secure_url);
            console.log("Image uploaded successfully:", result.info.secure_url);

            // Update the user's profile in Firestore
            try {
              const userDocRef = doc(db, "users", currentUser.uid);
              await updateDoc(userDocRef, {
                profilePictureURL: result.info.secure_url,
              });
              toast.success("Your profile picture has been updated.");
            } catch (error) {
              console.error(
                "Error updating profile picture in Firestore:",
                error
              );
              toast.error(
                "Failed to update profile picture. Please try again."
              );
            }
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload profile picture. Please try again.");
          }
        }
      )
      .open();
  };

  const handleSaveChanges = async () => {
    try {
      // Check if any changes have been made
      const changes = {};

      if (about !== undefined && about !== originalAbout) {
        changes.about = about;
      }
      if (phoneNumber !== undefined && phoneNumber !== originalPhoneNumber) {
        changes.phoneNumber = phoneNumber;
      }

      if (Object.keys(changes).length === 0) {
        // No changes were made
        toast.info("No changes were made.");
        return;
      }

      setIsLoading(true);

      // Update the user's document with the changes
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, changes);

      // Update original values
      if (changes.about !== undefined) setOriginalAbout(changes.about);
      if (changes.phoneNumber !== undefined)
        setOriginalPhoneNumber(changes.phoneNumber);

      setIsLoading(false);
      toast.success("Profile changes saved successfully.");
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to save changes: " + error.message);
    }
  };

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  // Function to update all listings with the new location
  const updateListingsLocation = async (newLocation, newGeolocation) => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("lenderUid", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);

      querySnapshot.forEach((docSnapshot) => {
        const listingDocRef = doc(db, "listings", docSnapshot.id);
        batch.update(listingDocRef, {
          addressComponents: newLocation.addressComponents,
          formattedAddress: newLocation.formattedAddress,
          _geoloc: newGeolocation,
        });
      });

      await batch.commit();
      console.log("Listings updated successfully.");
    } catch (error) {
      console.error("Error updating listings:", error);
      throw error;
    }
  };

  // Handle confirmation of new location
  const handleConfirmNewLocation = async () => {
    try {
      setIsLoading(true);
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        formattedAddress: newLocation.formattedAddress,
        addressComponents: newLocation.addressComponents,
        _geoloc: newGeolocation,
      });

      // Apply updates to the component state
      setFormattedAddress(newLocation.formattedAddress);
      setAddressComponents(newLocation.addressComponents);
      setGeolocation(newGeolocation);

      setIsLocationModalOpen(false);

      await updateListingsLocation(newLocation, newGeolocation);

      toast.success("Location updated successfully.");
    } catch (error) {
      console.error("Error updating location:", error);
      toast.error("Failed to update location.");
    } finally {
      setIsLoading(false);
    }
  };

  const sendAdminNotification = async (user, futureBookingsList) => {
    try {
      // Code to send an email notification via SendGrid to the admin team
      console.log(
        "Admin notification sent with user data and future bookings."
      );
    } catch (error) {
      console.error("Error sending admin notification:", error);
    }
  };

  const deleteUserListings = async () => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("lenderUid", "==", currentUser.uid));

      const listingsSnapshot = await getDocs(q);

      const batch = writeBatch(db);
      listingsSnapshot.forEach((listingDoc) => {
        const listingDocRef = doc(db, "listings", listingDoc.id);
        batch.delete(listingDocRef);
      });

      await batch.commit();
      console.log("User listings deleted successfully.");
    } catch (error) {
      console.error("Error deleting user listings:", error);
      throw error;
    }
  };

  const handleDeleteAccountRequest = async () => {
    try {
      setDeletingAccount(true);
      if (!currentUser) {
        toast.error("User is not logged in.");
        setDeletingAccount(false);
        return;
      }

      const reauthSuccess = await reauthenticateUser();
      if (!reauthSuccess) {
        setDeletingAccount(false);
        return; // Stop the process if re-authentication fails
      }

      const bookingsRef = collection(db, `users/${currentUser.uid}/bookings`);
      const rentalsRef = collection(db, `users/${currentUser.uid}/rentals`);

      const currentTimestamp = new Date().getTime();

      const futureBookingsQuery = query(
        bookingsRef,
        where("endDate", ">", currentTimestamp)
      );
      const futureRentalsQuery = query(
        rentalsRef,
        where("endDate", ">", currentTimestamp)
      );

      const [bookingsSnapshot, rentalsSnapshot] = await Promise.all([
        getDocs(futureBookingsQuery),
        getDocs(futureRentalsQuery),
      ]);

      const futureBookingsList = [
        ...bookingsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...rentalsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      if (futureBookingsList.length > 0) {
        setFutureBookings(futureBookingsList);

        toast.error(
          "You have future bookings or rentals. Account deletion is not possible at this time."
        );

        // Trigger the email notification to admin
        sendAdminNotification(currentUser, futureBookingsList);
        setDeletingAccount(false);
        return; // Exit early as we won't proceed with deletion
      }

      // Delete user listings
      await deleteUserListings();

      // Proceed with account deletion
      const auth = getAuth();
      await deleteUser(auth.currentUser);
      await deleteDoc(doc(db, "users", currentUser.uid));
      await signOut(auth);
      setDeletingAccount(false);
      navigate("/");
      toast.success("Account deleted successfully.");
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Failed to delete account. Please try again.");
      setDeletingAccount(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mb-5 px-5 md:px-0">
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 text-center mt-5 text-xl font-bold header__center">
          My Profile
        </h1>
      </div>
      <div className="mt-5">
        <div className="relative rounded-full w-40 h-40 bg-gray-200 overflow-hidden">
          <img
            src={profilePictureURL ? profilePictureURL : profilePicPlaceholder}
            alt="ProfilePicture"
            className="object-cover w-full h-full text-center"
            // onError={(e) => (e.target.src = "path/to/your/default-avatar.jpg")} // Placeholder if the image fails to load
          />

          <button
            onClick={() => openUploadWidget()}
            className="absolute bottom-5 right-5 p-2 bg-white rounded-full text-teal-500 hover:text-teal-600"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      </div>

      {currentUser?.emailVerified ? (
        <p className="text-sm md:text-base p-3 border-2 my-3 rounded-md border-emerald-500 font-semibold text-gray-700">
          Email verified{" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-emerald-600 text-lg"
          />
        </p>
      ) : (
        <p className="text-sm md:text-base p-3 border-2 my-3 rounded-md border-red-500 font-semibold text-gray-700">
          Email unverified <FontAwesomeIcon icon={faXmarkCircle} />
        </p>
      )}

      {currentUser?.verificationStatus &&
      currentUser.verificationStatus === "approved" ? (
        <p className="text-sm md:text-base p-3 border-2 rounded-md border-emerald-500 font-semibold text-gray-700">
          ID Verified{" "}
          <FontAwesomeIcon
            icon={faShieldAlt}
            className="text-emerald-600 text-lg"
          />
        </p>
      ) : (
        // <StripeVerifyIdentityButton currentUser={currentUser} />
        <VeriffButton currentUser={currentUser} removeTooltip={true} />
      )}
      <div className="w-full max-w-md">
        <hr className="my-5"></hr>
        {/* Profile Information */}
        <label className="block">
          <span className="text-gray-700 font-bold text-sm">About You</span>
          <textarea
            id="about"
            placeholder="Add a few words about yourself.."
            value={about}
            onChange={onChange}
            className="text-sm mt-1 block w-full rounded-md border-gray-400 shadow-sm"
          />
        </label>

        <label className="block mt-4">
          <span className="text-gray-700 font-bold text-sm">Email</span>
          <input
            type="text"
            id="email"
            value={email}
            disabled={true}
            onChange={onChange}
            className="text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </label>
        <div className="flex flex-col mt-5 shadow-sm text-sm">
          <span className="text-gray-700 font-bold text-sm">Username</span>
          {usernameIsTaken ? (
            <label className="text-sm font-bold text-red-500">
              {username} is not available
            </label>
          ) : usernameIsAvailable && username.length >= 4 ? (
            <label className="text-xs font-bold text-green-500">
              {username} is available
            </label>
          ) : null}
          {/* {usernameIsAvailable && username.length >= 4 ? (
                    <label className="text-sm font-bold text-green-500">
                      Username is available
                    </label>
                  ) : null} */}
          <input
            className={
              username.length >= 4 && usernameIsTaken
                ? "rejected p-2 text-sm mt-1"
                : username.length >= 4 && usernameIsAvailable
                ? "accepted p-2 text-sm mt-1"
                : "p-2 text-sm mt-1 "
            }
            type="text"
            id="username"
            value={username}
            disabled={true}
            onChange={onChangeUsername}
            placeholder="Username"
          ></input>
        </div>
        <label className="block mt-4">
          <span className="text-gray-700 font-bold text-sm">Phone Number</span>
          <input
            onChange={onChange}
            id="phoneNumber"
            type="text"
            value={phoneNumber}
            className="text-sm md:text-base mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </label>

        <div className="flex justify-between align-center items-center">
          <label className="block mt-4">
            <span className="text-gray-700 font-bold text-sm">
              General Location
            </span>
            <div className="text-sm md:text-base mt-1">{formattedAddress}</div>
          </label>

          <button
            className="btn-secondary px-2 sm:px-3 text-sm sm:text-base mt-2"
            onClick={() => setIsLocationModalOpen(true)}
          >
            Change Location
          </button>
        </div>

        <div className="flex w-full justify-center align-center items-center gap-5">
          <div className="flex justify-center">
            <button
              className="btn-secondary px-2 sm:px-3 text-sm sm:text-base"
              onClick={openUploadWidget}
              type="button"
            >
              <FontAwesomeIcon icon={faUpload} /> Update Profile Picture
            </button>
          </div>

          <div className="flex justify-center">
            {isLoading ? (
              <button
                onClick={() => handleSaveChanges()}
                className="btn-continue px-3 my-3"
              >
                <BeatLoader color="white" />
              </button>
            ) : (
              <button
                onClick={() => handleSaveChanges()}
                className="btn-continue px-3 my-3 text-sm sm:text-base"
              >
                <FontAwesomeIcon icon={faSave} /> Save Changes
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-3">
        <button
          className="btn-delete px-2 sm:px-3 text-sm sm:text-base"
          onClick={() => setIsConfirmDeleteAccountModalOpen(true)}
          disabled={deletingAccount}
          type="button"
        >
          <FontAwesomeIcon icon={faUserXmark} /> Delete Account
        </button>
      </div>

      {/* Confirm Delete Account Modal */}
      <Modal
        isOpen={isConfirmDeleteAccountModalOpen}
        onRequestClose={() => setIsConfirmDeleteAccountModalOpen(false)}
        contentLabel="Delete Account"
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="p-4">
          <div className="flex justify-between align-center items-center">
            <h2 className="text-lg font-semibold mb-4">Delete Your Account</h2>
            <div
              className="hover:cursor-pointer hover:font-bold text-xl"
              onClick={() => setIsConfirmDeleteAccountModalOpen(false)}
            >
              <FaX />
            </div>
          </div>
          <p className="mb-4 text-gray-700">
            Deleting your account is irreversible. Please enter your password to
            confirm.
          </p>
          <label className="block mt-4">
            <span className="text-gray-700 font-bold text-sm">Password</span>
            <input
              type="password"
              id="deleteAccountPassword"
              value={deleteAccountPassword}
              onChange={(e) => setDeleteAccountPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            />
          </label>
          <div className="mt-4 flex justify-center gap-3">
            <button
              onClick={() => setIsConfirmDeleteAccountModalOpen(false)}
              className="btn-cancel px-5"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteAccountRequest}
              className="btn-continue px-5"
            >
              Delete Account
            </button>
          </div>
        </div>
      </Modal>

      {/* Location Change Modal */}
      <Modal
        isOpen={isLocationModalOpen}
        onRequestClose={() => setIsLocationModalOpen(false)}
        contentLabel="Change Location"
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="md:p-2">
          <div className="flex justify-between align-center items-center mb-4">
            <h2 className="text-lg font-semibold">Change Your Location</h2>
            <div
              className="hover:cursor-pointer hover:font-bold text-lg md:text-xl"
              onClick={() => setIsLocationModalOpen(false)}
            >
              <FaX />
            </div>
          </div>
          <p className="text-sm md:text-base mb-4 text-gray-700">
            Changing your location will update the location of all your listings
            to this new location.
          </p>
          {/* <LocationSearch
            onLocationSelect={handleLocationChange}
            inputValue={locationInputValue}
            setInputValue={setLocationInputValue}
          /> */}
          <GoogleLocationSearch onLocationSelect={handleLocationSelected} />
          <div className="mt-4 flex justify-center gap-3">
            <button
              onClick={() => setIsLocationModalOpen(false)}
              className="text-sm md:text-base btn-secondary px-5"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmNewLocation}
              className={
                isLocationChanged
                  ? "text-sm md:text-base btn-confirm px-5"
                  : "text-sm md:text-base btn-secondary px-5"
              }
              disabled={!isLocationChanged}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProfile;
