import React, { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaInstagram } from "react-icons/fa6";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { name, email, message } = formData;

  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendContactUsFormEmail = async () => {
    const body = JSON.stringify({
      name,
      email,
      message,
    });

    try {
      const response = await fetch(
        `https://contactusformemail-contactusformemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
    } catch (error) {
      toast.error(
        "Failed to send email to KitShare, please contact us using other methods.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await sendContactUsFormEmail();
      toast.success(
        "KitShare emailed, keep an eye on your inbox for a response."
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Failed to email KitShare, please contact us using another method"
      );
      setIsLoading(false);
    }
    // Reset form after submission
    // setFormData({ name: "", email: "", message: "" });
    navigate("/");
  };

  return (
    <div className="max-w-5xl mx-auto px-6 mt-5">
      {/* Header Section */}
      <div className="flex w-full justify-center mb-5">
        <h1 className="w-2/3 text-center text-2xl font-bold header__center">
          Contact Us
        </h1>
      </div>

      {/* Contact Cards */}
      <div className="flex justify-center gap-6 mb-12">
        {/* Email Card */}
        <div className="min-w-[250px] bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
          {/* <img
            src="/path-to-email-icon.png"
            alt="Email Icon"
            className="w-16 h-16 mb-4"
          /> */}
          <MdOutlineMail className="text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Email Us</h2>
          <a href="mailto:info@kitshare.ie" className="text-blue-600">
            info@kitshare.ie
          </a>
        </div>

        {/* Phone Card */}
        {/* <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
          <HiOutlinePhone className="text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Call Us</h2>
          <p className="">+353 87 644 4397</p>
        </div> */}

        {/* Social Media Card */}
        <div className="min-w-[250px] bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
          {/* <img
            src="/path-to-social-icons.png"
            alt="Social Media Icons"
            className="w-16 h-16 mb-4"
          /> */}

          <IoShareSocialOutline className="text-4xl mb-2" />
          <h2 className="text-xl font-semibold mb-2">Follow Us</h2>
          <div className="flex gap-5 justify-center items-center align-center">
            <FaFacebook
              className="hover:cursor-pointer text-3xl"
              onClick={() => console.log("clicked")}
            />
            <FaInstagram
              className="hover:cursor-pointer text-3xl"
              onClick={() => console.log("clicked")}
            />
          </div>
        </div>
      </div>

      {/* Contact Form */}
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded-lg p-6"
      >
        <h2 className="text-2xl font-semibold mb-4">Send Us a Message</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 mb-1 font-semibold"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-teal-500 focus:border-2"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 mb-1 font-semibold"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-teal-500 focus:border-2"
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 mb-1 font-semibold"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="w-full border border-gray-300 rounded-lg px-3 py-2 h-32 focus:outline-none focus:border-teal-500 focus:border-2"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="btn-continue px-5"
            disabled={isLoading}
          >
            {isLoading ? <BeatLoader color="white" /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
