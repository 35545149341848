import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaCircleInfo } from "react-icons/fa6";
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Import Firestore functions

const VeriffButton = ({ currentUser, removeTooltip }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [verificationAttempts, setVerificationAttempts] = useState(0);

  useEffect(() => {
    const fetchVerificationAttempts = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const userRef = doc(db, "users", currentUser.uid);
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setVerificationAttempts(userData.verificationAttempts || 0);
          } else {
            console.error("User data not found.");
            toast.error("User data not found.", { autoClose: 5000 });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast.error("Error fetching user data.", { autoClose: 5000 });
        }
      }
    };

    fetchVerificationAttempts();
  }, [currentUser]);

  const handleVerification = async () => {
    if (!currentUser) {
      toast.error("You need to be logged in to verify your identity.");
      return;
    }

    if (verificationAttempts >= 3) {
      toast.error(
        "You have reached the maximum number of verification attempts. Please contact support at support@kitshare.ie.",
        { autoClose: 5000 }
      );
      return;
    }

    setIsLoading(true);

    const body = JSON.stringify({
      userUid: currentUser.uid,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    });

    try {
      const response = await fetch(
        `https://startveriffsession-startveriffsession-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to create Veriff session.");
      }

      const data = await response.json();
      setIsLoading(false);
      window.location.href = data.verificationUrl; // Redirect to Veriff session
    } catch (error) {
      console.log("Error creating Veriff session:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong creating the verification session.", {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="relative inline-block">
        <button
          className="btn-primary px-5"
          onClick={handleVerification}
          disabled={isLoading || verificationAttempts >= 3}
        >
          {isLoading
            ? "Processing..."
            : verificationAttempts >= 3
            ? "Verification Limit Reached"
            : "Click to Verify ID"}
        </button>

        {/* Info Icon with Tooltip */}
        {removeTooltip ? null : (
          <FaCircleInfo
            className="ml-2 inline cursor-pointer text-gray-500 hover:text-gray-700"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        )}
        {showTooltip && (
          <div className="absolute top-0 left-full ml-3 w-72 bg-white text-gray-800 p-3 rounded-md shadow-lg z-10">
            <p className="text-sm">
              🌟 At KitShare, we prioritize trust and safety. Verifying your ID
              helps us create a secure community where everyone can confidently
              rent and share items.
            </p>
            <p className="text-sm font-semibold mt-2">
              You must verify your ID before you can rent or accept payments.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default VeriffButton;
