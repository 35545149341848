import React, { useState } from "react";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../utils/formatCurrency";

const MyBookingsMobile = ({
  bookings,
  handleShowCancelBooking,
  isCancelling,
  showCancellationModal,
}) => {
  const [openBookingId, setOpenBookingId] = useState(null);

  const toggleBookingDetails = (bookingId) => {
    setOpenBookingId(openBookingId === bookingId ? null : bookingId);
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap gap-4 px-4 md:py-6">
      {bookings.map((booking) => (
        <div
          key={booking.id}
          className="w-full bg-white shadow-md rounded-lg p-4"
        >
          {/* Booking Summary */}
          <div className="" onClick={() => toggleBookingDetails(booking.id)}>
            <div className="flex items-center justify-between">
              <h2
                className="text-lg font-bold hover:cursor-pointer hover:underline"
                onClick={() => navigate(`/listings/${booking.listingId}`)}
              >
                {booking.listingDetails.title}
              </h2>
              <div>
                {/* <img src={booking.imageURLs[0]} alt="rentalImage" /> */}
                <p className="text-sm font-semibold text-gray-500">
                  {booking.formattedStartDate} - {booking.formattedEndDate}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-2 text-sm text-gray-700">
              <p
                className="hover:cursor-pointer hover:underline hover:font-semibold"
                onClick={() => navigate(`/users/${booking.renterUid}`)}
              >
                <span className="font-semibold">Renter: </span>
                {booking.renterUsername}
              </p>
              <p>
                <span className="font-semibold">Number of days: </span>
                {booking.numberOfDays}
              </p>

              <p>
                <span className="font-semibold">Guarantee package: </span>{" "}
                {booking.guaranteePackage?.name || "None"}
              </p>
              {booking.guaranteePackage ? (
                <p>
                  <span className="font-semibold">
                    <span>Package fee:</span>
                  </span>{" "}
                  €{formatCurrency(booking.guaranteePackage?.feeCents)}
                </p>
              ) : null}

              {booking.guaranteePackage?.feeCents > 0 ? (
                <p>
                  <span className="font-semibold">Earnings: </span>€
                  {/* {(booking.earnings - booking.guaranteePackage.fee).toFixed(2)} */}
                  {formatCurrency(booking.netEarningsCents)}
                </p>
              ) : (
                <p>
                  <span className="font-semibold">Earnings: </span>€
                  {formatCurrency(booking.grossEarningsCents)}
                </p>
              )}
              <div className="flex justify-center w-full">
                {/* <button
                  onClick={() => handleShowCancelBooking(booking)}
                  disabled={isCancelling}
                  className="btn-cancel w-1/2 px-3 py-1 text-sm"
                >
                  {isCancelling ? <BeatLoader /> : "Cancel Booking"}
                </button> */}
                {firestoreTimestampToDate(booking.startDate) > new Date() ? (
                  <button
                    onClick={() => handleShowCancelBooking(booking)}
                    className="btn-cancel px-2 py-1"
                  >
                    <FontAwesomeIcon icon={faCancel} /> Cancel Booking
                  </button>
                ) : (
                  <div className="px-5 py-2 bg-gray-100 rounded-lg font-semibold shadow-md">
                    N/A
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyBookingsMobile;
