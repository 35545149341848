import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faInfoCircle,
  faPlusCircle,
  faShieldAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import { SyncLoader } from "react-spinners";
import CategorySelector from "../components/CategorySelector";
import { useAuth } from "../hooks/useAuth";

const useSafeDispatch = (dispatch) => {
  const mounted = React.useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (action) => {
    if (mounted.current) {
      dispatch(action);
    }
  };
};

const NewListing = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dailyRate: "",
    threeDayCost: "",
    sevenDayCost: "",
    location: "",
    deposit: "",
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [userExistingFormattedAddress, setUserExistingFormattedAddress] =
    useState("");
  const [userExistingAddressComponents, setUserExistingAddressComponents] =
    useState({});
  const [userExistingGeoLocation, setUserExistingGeoLocation] = useState({});
  const [imageURLs, setImageURLs] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [emailVerified, setEmailVerified] = useState(false);

  const safeSetShowSpinner = useSafeDispatch(setShowSpinner);

  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const { title, description, dailyRate, threeDayCost, sevenDayCost, deposit } =
    formData;

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  const getUserExistingLocation = async (userUid) => {
    try {
      const userRef = doc(db, "users", userUid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setUserExistingGeoLocation(userSnap.data()._geoloc);
        setUserExistingAddressComponents(userSnap.data().addressComponents);
        setUserExistingFormattedAddress(userSnap.data().formattedAddress);
      }
    } catch (error) {
      console.error("Failed to get user location:", error);
      toast.error("Failed to retrieve user location. Please try again.");
    }
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        try {
          // Reload user data to get the latest emailVerified status
          await auth.currentUser.reload();
          const updatedEmailVerified = auth.currentUser.emailVerified;

          setEmailVerified(updatedEmailVerified);

          // Optionally, update the context
          setCurrentUser((prevUser) => ({
            ...prevUser,
            emailVerified: updatedEmailVerified,
          }));

          if (!updatedEmailVerified) {
            toast.error(
              "You need to verify your email before creating a listing. Please check your emails for a verification link.",
              {
                autoClose: 5000,
              }
            );
            navigate("/");
          } else {
            getUserExistingLocation(auth.currentUser.uid);
          }
        } catch (error) {
          console.error("Error reloading user data:", error);
          toast.error("Failed to verify user status. Please try again.");
          navigate("/");
        }
      } else {
        toast.error("You need to be signed in to create a listing.", {
          autoClose: 3000,
        });
        navigate("/sign-in");
      }
    };

    checkUserStatus();
  }, [navigate, setCurrentUser]);

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
  };

  const handleSubcategorySelected = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const addListingToUserOwnedListings = async (listingId) => {
    try {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        ownedListings: arrayUnion(listingId),
      });
    } catch (error) {
      console.error("Failed to add listing to user-owned listings:", error);
      toast.error("Failed to update user-owned listings. Please try again.");
    }
  };

  const ratesAreCorrect = (
    dailyRateCents,
    threeDayCostCents,
    sevenDayCostCents
  ) => {
    const daily = dailyRateCents;
    const threeDay = threeDayCostCents;
    const sevenDay = sevenDayCostCents;

    if (isNaN(daily)) {
      return false;
    }

    if (threeDay && sevenDay) {
      return (
        threeDay <= daily * 3 && sevenDay <= daily * 7 && threeDay <= sevenDay
      );
    } else if (threeDay) {
      return threeDay <= daily * 3;
    } else if (sevenDay) {
      return sevenDay <= daily * 7;
    } else {
      // Only dailyRate is provided
      return true;
    }
  };

  const createNewListing = async (e) => {
    e.preventDefault();

    // Convert to cents
    const dailyRateCents = Math.round(parseFloat(dailyRate) * 100);
    const threeDayCostCents = threeDayCost
      ? Math.round(parseFloat(threeDayCost) * 100)
      : null;
    const sevenDayCostCents = sevenDayCost
      ? Math.round(parseFloat(sevenDayCost) * 100)
      : null;
    const depositCents = deposit ? Math.round(parseFloat(deposit) * 100) : null;

    if (
      !ratesAreCorrect(dailyRateCents, threeDayCostCents, sevenDayCostCents)
    ) {
      toast.error(
        "Something looks wrong with your rates. Your 3 day price should be larger than the daily rate. The 7 day price should be larger than the 3 day price.",
        { autoClose: 5000 }
      );
      return;
    }

    if (depositCents === 0) {
      toast.info(
        "Please remove 0 from optional deposit, leave this blank instead.",
        {
          autoClose: 5000,
        }
      );
      return;
    }

    if (
      !title ||
      !description ||
      !dailyRate ||
      imageURLs.length === 0 ||
      !selectedCategory
    ) {
      toast.error(
        "Missing form information. Please ensure all required fields are filled and images are uploaded.",
        {
          autoClose: 5000,
        }
      );
      return;
    }

    const geoloc = {
      lat: userExistingGeoLocation.lat,
      lng: userExistingGeoLocation.lng,
    };

    // const loc = {
    //   town: userExistingLocation.town,
    //   county: userExistingLocation.county,
    // };

    const formattedAddress = userExistingFormattedAddress;
    const addressComponents = userExistingAddressComponents;

    try {
      safeSetShowSpinner(true);

      console.log("Form data is valid, creating new listing...");

      let calculatedThreePlusDayRateCents;
      let calculatedSevenPlusDayRateCents;

      if (threeDayCostCents) {
        calculatedThreePlusDayRateCents = Math.round(threeDayCostCents / 3);
      }

      if (sevenDayCostCents) {
        calculatedSevenPlusDayRateCents = Math.round(sevenDayCostCents / 7);
      }

      const listingData = {
        title,
        category: selectedCategory.value,
        subcategory: selectedSubcategory ? selectedSubcategory.value : "",
        description,
        lenderUid: currentUser?.uid,
        dailyRateCents: dailyRateCents,
        imageURLs,
        addressComponents,
        formattedAddress,
        _geoloc: geoloc,
        status: "active",
        createdAt: serverTimestamp(),
      };

      if (depositCents && depositCents > 0) {
        listingData.depositCents = depositCents;
      }

      if (threeDayCostCents) {
        listingData.threeDayCostCents = threeDayCostCents;
        listingData.threePlusDayRateCents = calculatedThreePlusDayRateCents;
      }

      if (sevenDayCostCents) {
        listingData.sevenDayCostCents = sevenDayCostCents;
        listingData.sevenPlusDayRateCents = calculatedSevenPlusDayRateCents;
      }

      const listingRef = await addDoc(collection(db, "listings"), listingData);

      await addListingToUserOwnedListings(listingRef.id);

      safeSetShowSpinner(false);
      toast.success("Your listing has been successfully created!", {
        autoClose: 2000,
      });
      navigate(`/my-listings`);
    } catch (error) {
      safeSetShowSpinner(false);
      console.error("Error creating new listing:", error);
      toast.error("Failed to create listing. Please try again.");
    }
  };

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.type === "number") {
      if (value !== "") {
        value = parseFloat(value);
        if (isNaN(value)) value = null;
      } else {
        value = null;
      }
    }

    if (e.target.id === "deposit" && value === 0) {
      toast.info("Leave this blank if no deposit is required.");
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const cloudName = "prodcloudinary";
  const uploadPreset = "kitShareUploadPreset";

  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: true,
          maxFiles: 5 - imageFiles.length,
          sources: ["local", "camera"],
          folder: "listingImages",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "image",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setImageFiles((prevFiles) => [...prevFiles, result.info]);
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
            console.log("Image uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload image. Please try again.");
          }
        }
      )
      .open();
  };

  const removeImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageURLs((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  return (
    <div className="force-footer">
      <div className="main-content bg-gray-50">
        <div className="flex w-full justify-center">
          <h1 className="w-full md:w-2/3 text-center my-5 text-xl font-bold header__center">
            Create New Listing
          </h1>
        </div>

        <div className="flex justify-center">
          <form
            className="w-11/12 md:w-2/3 xl:w-4/12"
            onSubmit={createNewListing}
          >
            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Listing Title</label>
              <input
                className="shadow-md outline-none focus:border-emerald-500"
                type="text"
                maxLength={35}
                id="title"
                value={title}
                onChange={onChange}
                placeholder="Enter the title of your listing"
                required
              />
            </div>

            <CategorySelector
              onCategorySelected={handleCategorySelected}
              onSubcategorySelected={handleSubcategorySelected}
            />

            <div className="flex flex-col w-full py-2 mb-2 mt-3">
              <label className="label">Listing Description</label>
              <textarea
                placeholder="Enter listing details"
                value={description}
                id="description"
                onChange={onChange}
                className="border-2 p-2 shadow-md outline-none focus:border-emerald-500"
                required
                rows={10}
              />
            </div>

            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Rental price (€):</label>
              <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-2">
                <div className="w-full sm:w-1/3">
                  <label className="label">Daily rate</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    type="number"
                    min={1}
                    required
                    step="0.01"
                    id="dailyRate"
                    value={dailyRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">Price for 3 days</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    type="number"
                    step="0.01"
                    id="threeDayCost"
                    placeholder="Optional"
                    value={threeDayCost}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">Price for 7 days</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    type="number"
                    placeholder="Optional"
                    id="sevenDayCost"
                    step="0.01"
                    value={sevenDayCost}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <div className="w-full my-4">
              <div className="bg-white rounded-lg shadow-md p-4 mb-1">
                <label className="text-base md:text-lg font-semibold mb-4 block">
                  Deposit required?
                </label>
                <div className="flex flex-col gap-4">
                  {/* Deposit Information */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You can request a deposit for your item to help ensure its
                      safe return.
                    </p>
                  </div>
                  {/* Insurance Opportunity */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You'll also have the opportunity to{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/guarantee")}
                      >
                        select one of our guarantee packages
                      </span>{" "}
                      for each booking.
                    </p>
                  </div>
                  {/* Terms & Conditions */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      For more details, please review our{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/terms-and-conditions")}
                      >
                        Terms & Conditions
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>

              <input
                className="text-sm md:text-base shadow-md outline-none focus:border-emerald-500"
                type="number"
                placeholder="Optional Deposit"
                min={1}
                step="0.01"
                id="deposit"
                value={deposit}
                onChange={onChange}
              />
            </div>

            <div className="flex flex-col items-center py-4">
              {/* Display image thumbnails */}
              {imageURLs.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {imageURLs.map((url, index) => (
                    <div key={index} className="relative">
                      <img
                        src={url}
                        alt="Uploaded"
                        className="w-20 h-20 object-cover rounded-md shadow-md"
                      />
                      <button
                        type="button"
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 hover:bg-red-700"
                        onClick={() => removeImage(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-center gap-5">
              {imageFiles.length < 5 && (
                <button
                  className="text-sm md:text-base btn-edit px-4"
                  onClick={openUploadWidget}
                  type="button"
                  aria-label="Upload Images"
                >
                  <FontAwesomeIcon icon={faUpload} /> Upload Images (max 5)
                </button>
              )}
              <button
                type="submit"
                className={`text-sm md:text-base px-7 ${
                  !title ||
                  !description ||
                  !dailyRate ||
                  imageFiles.length === 0
                    ? "btn-secondary"
                    : "btn-continue"
                }`}
                aria-label="Create Listing"
                disabled={
                  showSpinner ||
                  !title ||
                  !description ||
                  !dailyRate ||
                  imageFiles.length === 0
                }
              >
                {showSpinner ? (
                  <SyncLoader size={10} margin={2} color="white" />
                ) : (
                  <span className="text-sm md:text-base text-base">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color={
                        !title ||
                        !description ||
                        !dailyRate ||
                        imageFiles.length === 0
                          ? "black"
                          : "white"
                      }
                    />{" "}
                    Create Listing
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NewListing;
