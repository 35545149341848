// GoogleLocationSearch.js
import React from "react";
import { Autocomplete } from "@react-google-maps/api";

const GoogleLocationSearch = ({
  onLocationSelect,
  isLoaded,
  inputValue,
  setInputValue,
}) => {
  const [autocomplete, setAutocomplete] = React.useState(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const extractAddressComponents = (addressComponents) => {
    const components = {
      neighbourhood: "",
      county: "",
      locality: "",
      postal_code: "",
      administrative_area_level_2: "",
      administrative_area_level_1: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      const longName = component.long_name;

      if (types.includes("neighborhood")) {
        components.neighbourhood = longName;
      }
      if (types.includes("locality")) {
        components.locality = longName;
      }
      if (types.includes("administrative_area_level_2")) {
        components.administrative_area_level_2 = longName;
      }
      if (types.includes("administrative_area_level_1")) {
        components.administrative_area_level_1 = longName;
      }
    });

    let county = "";
    if (components.administrative_area_level_2) {
      county = components.administrative_area_level_2;
    } else if (components.administrative_area_level_1) {
      county = components.administrative_area_level_1;
    }

    if (county.startsWith("County ")) {
      county = county.replace("County ", "");
    }

    components.county = county;
    return components;
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error("No details available for input: " + place.name);
        return;
      }

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const components = extractAddressComponents(
        place.address_components || []
      );

      const formattedAddressComponents = [];
      if (components.neighbourhood) {
        formattedAddressComponents.push(components.neighbourhood);
      } else if (components.locality) {
        formattedAddressComponents.push(components.locality);
      } else if (components.administrative_area_level_2) {
        formattedAddressComponents.push(components.administrative_area_level_2);
      } else if (components.administrative_area_level_1) {
        formattedAddressComponents.push(components.administrative_area_level_1);
      }

      const adminAreas = [
        components.locality,
        components.administrative_area_level_2,
        components.administrative_area_level_1,
      ];

      for (const area of adminAreas) {
        if (
          area &&
          area !== formattedAddressComponents[0] &&
          !formattedAddressComponents.includes(area)
        ) {
          formattedAddressComponents.push(area);
          break;
        }
      }

      const formattedAddress = formattedAddressComponents.join(", ");
      setInputValue(formattedAddress);

      const locationData = {
        geolocation: { lat, lng },
        formattedAddress,
        addressComponents: components,
      };

      if (onLocationSelect) {
        onLocationSelect(locationData);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  if (!isLoaded) {
    return <div>Loading location search...</div>;
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={{
        componentRestrictions: { country: "ie" },
        fields: ["address_components", "geometry", "formatted_address", "name"],
      }}
    >
      <input
        type="text"
        placeholder="Search Suburb, Town, City, or County..."
        value={inputValue}
        className="text-sm md:text-base"
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Autocomplete>
  );
};

export default GoogleLocationSearch;
